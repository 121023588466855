import { SERVICE_URL } from 'config';
import api from '../api';
let chatData = [];
let chatData1 = [
  { 
    id: 1,
    name: 'Blaine Cottrell',
    thumb: '/img/profile/profile-4.webp',
    last: 'Today 10:40',
    status: 'Online',
    unread: 0,
    messages: [
      {
        text: 'Hello there!',
        time: '17:20',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'How is it going?',
        time: '17:20',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'Here is the images you have asked for.',
        time: '17:21',
        type: 'response',
        // attachments: [],
      },
      {
        text: '',
        time: '17:21',
        type: 'response',
        // attachments: ['/img/product/large/product-1.webp', '/img/product/large/product-2.webp'],
      },
      {
        text: 'Carrot cake pie sweet roll cake. Danish apple pie danish muffin marzipan carrot cake. Danish pastry wafer halvah brownie muffin cookie cookie. Dessert sweet chocolate fruitcake. Toffee candy cake. Pie gummies dessert oat cake sweet roll powder jelly pastry chocolate cake. Gummi bears cake icing gummies marzipan croissant lemon drops soufflé.',
        time: '17:22',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'Fruitcake chupa chups gingerbread sweet roll pie. Tootsie roll sweet roll pudding. Pastry liquorice wafer sweet.',
        time: '17:23',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'Toffee croissant icing toffee. Sweet roll chupa chups marshmallow.',
        time: '19:10',
        type: 'message',
        // attachments: [],
      },
      {
        text: 'Chocolate cake lollipop dessert.',
        time: '19:11',
        type: 'message',
        // attachments: [],
      },
      {
        text: 'Tootsie roll sweet roll pudding.',
        time: '19:13',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'Danish pastry wafer halvah brownie muffin cookie cookie.',
        time: '19:15',
        type: 'response',
        // attachments: [],
      },
      {
        text: '',
        time: '19:15',
        type: 'message',
        // attachments: ['/img/product/large/product-3.webp'],
      },
      {
        text: 'Oat cake gummies cheesecake cake biscuit jelly beans. Muffin cheesecake sesame snaps.',
        time: '19:35',
        type: 'message',
        // attachments: [],
      },
      {
        text: 'Thanks for the help!',
        time: '21:22',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'And I see you soon!',
        time: '21:22',
        type: 'response',
        // attachments: [],
      },
      {
        text: 'Bye',
        time: '21:22',
        type: 'response',
        // attachments: [],
      },
    ],
  },
  
];

api.onGet(`${SERVICE_URL}/apps/chat`).reply(200, chatData);
api.onPut(`${SERVICE_URL}/apps/chat/addText`).reply((config) => {
  const requestData = JSON.parse(config.data);
  const { id, text } = requestData;
  const date = new Date();
  const selectedChat = chatData.find((x) => x.id === id);
  selectedChat.messages.push({
    text,
    time: `${date.getHours()}:${date.getMinutes()}`,
    type: 'message',
    // attachments: [],
  });
  // Add item
  chatData = [selectedChat, ...chatData.filter((x) => x.id !== id)];
  return [200, chatData];
});
api.onPut(`${SERVICE_URL}/apps/chat/addAttachments`).reply((config) => {
  const requestData = JSON.parse(config.data);
  const { id, attachments } = requestData;
  const date = new Date();
  const selectedChat = chatData.find((x) => x.id === id);
  selectedChat.messages.push({
    text: '',
    time: `${date.getHours()}:${date.getMinutes()}`,
    type: 'message',
    attachments,
  });
  // Add item
  chatData = [selectedChat, ...chatData.filter((x) => x.id !== id)];
  return [200, chatData];
});
api.onPut(`${SERVICE_URL}/apps/chat/read`).reply((config) => {
  const requestData = JSON.parse(config.data);
  const { id } = requestData;
  const selectedChat = { ...chatData.find((x) => x.id === id), unread: 0 };

  // Add item
  chatData = chatData.map((chat) => {
    if (chat.id === id) {
      return selectedChat;
    }
    return chat;
  });
  return [200, chatData];
});
