export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightYellow: 'light-yellow',
  DarkYellow: 'dark-yellow',
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
  LightOrange: 'light-orange',
  DarkOrange: 'dark-orange',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
 ActiveUser: 'Active',
 ExpiredUser: 'Expired'
};

export const AD_REQUIREMENTTYPE_OPTIONS = [{
  'value': 1,
  'label': 'One Time'
}, {
  'value': 2,
  'label': 'Reqular'
}];

export const AD_PURPOSE_OPTIONS = [{
  'value': 1,
  'label': 'Buying'
}, {
  'value': 2,
  'label': 'Selling'
}];

export const GOOGLE_SITEKEY = "6LcbzGApAAAAAKEH9Fd9LJWGTCfdDj7cOo3Jv4JS";