import { LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR, USER_ROLE } from 'constants.js';

export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: 'Steelandmetals.com: Online Steel Metal Freight e Auction Tender Portal',
  titleTemplate: 'Steelandmetals.com: Online Steel Metal Freight e Auction Tender Portal | %s',
};

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_EMAIL:'/verify-email/:token',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
  ABOUTSTEELANDMETALS: '/about-steelandmetals',
  ADVERTISEWITHUS: '/advertise-with-us',
  CAREEROPPORTUNITIES: '/career-opportunites',
  CONTACTUS: '/contact-us',
  CUSTOMERSUPPORT: '/customer-support',
  FAQ: '/faq',
  LEGALDISCLAIMER: '/legal-disclaimer',
  MEMBERCONDUCTRULES: '/member-conduct-rules',
  PRIVACYANDSECURITY: '/privacy-and-security',
  SERVICES: '/services',
  SITEMAP: '/site-map',
  TROUBLESHOOTING: '/troubleshooting',
  LOGINWITHOTP: '/login-with-otp',
  SUBMITUSERMATERIALREQUEST: '/submit-user-material-request',
  LASTAUCTIONPRICESPAGE: '/last-auctionprices-list',
  MARKETTRENDSPAGE: '/market-trends-list',
  MARKETOFFERSPAGE: '/market-offers-list',
  TESTIMONIALSPAGE: '/testimonials-list',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Horizontal,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Boxed,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightPurple,
  NAV_COLOR: NAV_COLOR.Default,
  USE_SIDEBAR: false,
};

export const DEFAULT_USER = {
  id: 1,
  name: 'Lisa Jackson',
  companyName: 'Lisa Jackson',
  thumb: '/img/profile/profile-11.webp',
  role: USER_ROLE.ActiveUser,
  email: 'lisajackson@gmail.com',
  state: 'Uttar Pradesh',
  city: 'Ghaziabad',
  gstn: 'GSTN21548745445',
  token: '',
  subsEndDate:'',
  subsStatus:''
};

export const REDUX_PERSIST_KEY = 'ecommerce-platform';
export const URL_PATH = 'https://t902hdapi.steelandmetals.com/api/bidder/v1/';
export const _URL_PATH = 'http://local.betaadmin.steelandmetals.com/api/bidder/v1/';
export const S3_PATH = 'https://s3.ap-south-1.amazonaws.com/steelandmetals.com/';