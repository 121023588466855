/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';


const defaultPages = {
  home :lazy(() => import('views/default/Home')),
  AboutSteelandmetals :lazy(() => import('views/cms/AboutSteelandmetals')),
  AdvertiseWithUs :lazy(() => import('views/cms/AdvertiseWithUs')),
  CareerOpportunities :lazy(() => import('views/cms/CareerOpportunities')),
  Contactus :lazy(() => import('views/cms/Contactus')),
  CustomerSupport :lazy(() => import('views/cms/CustomerSupport')),
  Faq :lazy(() => import('views/cms/Faq')),
  LegalDisclaimer :lazy(() => import('views/cms/LegalDisclaimer')),
  MemberConductRules :lazy(() => import('views/cms/MemberConductRules')),
  PrivacyAndSecurity :lazy(() => import('views/cms/PrivacyAndSecurity')),
  Services :lazy(() => import('views/cms/Services')),
  Sitemap :lazy(() => import('views/cms/Sitemap')),
  Troubleshooting :lazy(() => import('views/cms/Troubleshooting')),
  login :lazy(() => import('views/default/Login')),
  register :lazy(() => import('views/default/Register')),
  SubmitUserRequest: lazy(() => import('views/default/SubmitUserRequest')),
  LastAuctionPricesPage: lazy(() => import('views/default/LastAuctionPricesPage')),
  MarketTrendsPage: lazy(() => import('views/default/MarketTrendsPage')),
  MarketOffersPage: lazy(() => import('views/default/MarketOffersPage')),
  TestimonialsPage: lazy(() => import('views/default/TestimonialsPage')),
  VerifyEmail: lazy(() => import('views/default/VerifyEmail')),
  Error: lazy(() => import('views/default/Error')),
  InvalidAccess: lazy(() => import('views/default/InvalidAccess')),
  Unauthorized: lazy(() => import('views/default/Unauthorized'))
};


const dashboard = lazy(() => import('views/dashboard/Dashboard'));

const schedule = { 
  eventSchedule:lazy(() => import('views/schedule/list/ScheduleList')),
}
const ad = {
  myAdList:lazy(() => import('views/ads/MyAdList')),
  adPopup:lazy(() => import('views/ads/AdsPopup')),
  createMyAd:lazy(() => import('views/ads/CreateMyAd')),
  updateMyAd:lazy(() => import('views/ads/UpdateMyAd')),
  detailMyAd:lazy(() => import('views/ads/DetailMyAd')),
  chatAd:lazy(() => import('views/chat/Chat')),
  myAdchatUsers: lazy(() => import('views/ads/MyAdChatUserList'))
};
const BootstrapIconsPage = lazy(() => import('views/interface/content/icons/BootstrapIcons'));

const auction = {
  upcomingEventList: lazy(() => import('views/auction/list/UpcomingEventList')),
  eventList: lazy(() => import('views/auction/list/LiveEventList')),
  lotList: lazy(() => import('views/auction/list/LiveLotList')),
  bidpage: lazy(() => import('views/auction/list/Bidpage'))
};
const reports = {
  concluded: lazy(() => import('views/reports/ConcludedLotList')),
  mycart: lazy(() => import('views/reports/MyCartList')),
};
const account = {
  editprofile: lazy(() => import('views/account/EditProfile.js')),
  editcompanydetails: lazy(() => import('views/account/EditCompanyDetails.js')),
  editbankdetails: lazy(() => import('views/account/EditBankDetails.js')),
  membershipList: lazy(() => import('views/account/MembershipList.js')),
  depositList: lazy(() => import('views/account/DepositList.js')),
  personalizeList: lazy(() => import('views/account/PersonalizeList.js')),
  refundRequest: lazy(() => import('views/account/RefundRequest.js')),
  uploadDocuments: lazy(() => import('views/account/UploadDocuments.js')),
  refundRequestList: lazy(() => import('views/account/RefundRequestList.js')),
  changePassword: lazy(() => import('views/account/ChangePassword.js')),
  emdBlockedInEvent: lazy(() => import('views/account/EMDBlockedInEventList.js')),
};
// const orders = {
//   list: lazy(() => import('views/orders/list/OrdersList')),
//   detail: lazy(() => import('views/orders/detail/OrdersDetail')),
// };
// const customers = {
//   list: lazy(() => import('views/customers/list/CustomersList')),
//   detail: lazy(() => import('views/customers/detail/CustomersDetail')),
// };

// const storefront = {
//   home: lazy(() => import('views/storefront/home/Home')),
//   filters: lazy(() => import('views/storefront/filters/Filters')),
//   categories: lazy(() => import('views/storefront/categories/Categories')),
//   detail: lazy(() => import('views/storefront/detail/Detail')),
//   cart: lazy(() => import('views/storefront/cart/Cart')),
//   checkout: lazy(() => import('views/storefront/checkout/Checkout')),
//   invoice: lazy(() => import('views/storefront/invoice/Invoice')),
// };
// const shipping = lazy(() => import('views/shipping/Shipping'));
// const discount = lazy(() => import('views/discount/Discount'));

// const settings = {
//   home: lazy(() => import('views/settings/home/Home')),
//   general: lazy(() => import('views/settings/general/General')),
// };

// const blocks = {
//   index: lazy(() => import('views/blocks/Blocks')),
//   cta: lazy(() => import('views/blocks/cta/Cta')),
//   details: lazy(() => import('views/blocks/details/Details')),
//   gallery: lazy(() => import('views/blocks/gallery/Gallery')),
//   images: lazy(() => import('views/blocks/images/Images')),
//   list: lazy(() => import('views/blocks/list/List')),
//   stats: lazy(() => import('views/blocks/stats/Stats')),
//   steps: lazy(() => import('views/blocks/steps/Steps')),
//   tabularData: lazy(() => import('views/blocks/tabulardata/TabularData')),
//   thumbnails: lazy(() => import('views/blocks/thumbnails/Thumbnails')),
// };
// const interfaces = {
//   index: lazy(() => import('views/interface/Interface')),
//   components: {
//     index: lazy(() => import('views/interface/components/Components')),
//     accordion: lazy(() => import('views/interface/components/Accordion')),
//     alerts: lazy(() => import('views/interface/components/Alerts')),
//     badge: lazy(() => import('views/interface/components/Badge')),
//     breadcrumb: lazy(() => import('views/interface/components/Breadcrumb')),
//     buttons: lazy(() => import('views/interface/components/Buttons')),
//     buttonGroup: lazy(() => import('views/interface/components/ButtonGroup')),
//     card: lazy(() => import('views/interface/components/Card')),
//     closeButton: lazy(() => import('views/interface/components/CloseButton')),
//     collapse: lazy(() => import('views/interface/components/Collapse')),
//     dropdowns: lazy(() => import('views/interface/components/Dropdowns')),
//     listGroup: lazy(() => import('views/interface/components/ListGroup')),
//     modal: lazy(() => import('views/interface/components/Modal')),
//     navs: lazy(() => import('views/interface/components/Navs')),
//     offcanvas: lazy(() => import('views/interface/components/Offcanvas')),
//     pagination: lazy(() => import('views/interface/components/Pagination')),
//     popovers: lazy(() => import('views/interface/components/Popovers')),
//     progress: lazy(() => import('views/interface/components/Progress')),
//     spinners: lazy(() => import('views/interface/components/Spinners')),
//     toasts: lazy(() => import('views/interface/components/Toasts')),
//     tooltips: lazy(() => import('views/interface/components/Tooltips')),
//   },
//   forms: {
//     index: lazy(() => import('views/interface/forms/Forms')),
//     layouts: lazy(() => import('views/interface/forms/layouts/Layouts')),
//     validation: lazy(() => import('views/interface/forms/validation/Validation')),
//     wizard: lazy(() => import('views/interface/forms/wizard/Wizard')),
//     inputGroup: lazy(() => import('views/interface/forms/input-group/InputGroup')),
//     inputMask: lazy(() => import('views/interface/forms/input-mask/InputMask')),
//     genericForms: lazy(() => import('views/interface/forms/generic-forms/GenericForms')),
//     controls: {
//       index: lazy(() => import('views/interface/forms/controls/Controls')),
//       autocomplete: lazy(() => import('views/interface/forms/controls/autocomplete/Autocomplete')),
//       checkboxRadio: lazy(() => import('views/interface/forms/controls/checkbox-radio/CheckboxRadio')),
//       datePicker: lazy(() => import('views/interface/forms/controls/datepicker/Datepicker')),
//       dropzone: lazy(() => import('views/interface/forms/controls/dropzone/Dropzone')),
//       editor: lazy(() => import('views/interface/forms/controls/editor/Editor')),
//       inputSpinner: lazy(() => import('views/interface/forms/controls/input-spinner/InputSpinner')),
//       rating: lazy(() => import('views/interface/forms/controls/rating/Rating')),
//       select: lazy(() => import('views/interface/forms/controls/select/Select')),
//       slider: lazy(() => import('views/interface/forms/controls/slider/Slider')),
//       tags: lazy(() => import('views/interface/forms/controls/tags/Tags')),
//     },
//   },
//   plugins: {
//     index: lazy(() => import('views/interface/plugins/Plugins')),
//     carousel: lazy(() => import('views/interface/plugins/carousel/Carousel')),
//     charts: lazy(() => import('views/interface/plugins/chart/Chart')),
//     clamp: lazy(() => import('views/interface/plugins/clamp/Clamp')),
//     contextMenu: lazy(() => import('views/interface/plugins/context-menu/ContextMenu')),
//     datatables: {
//       index: lazy(() => import('views/interface/plugins/datatables/Datatables')),
//       editableRows: lazy(() => import('views/interface/plugins/datatables/EditableRows/EditableRows')),
//       editableBoxed: lazy(() => import('views/interface/plugins/datatables/EditableBoxed/EditableBoxed')),
//       serverSide: lazy(() => import('views/interface/plugins/datatables/ServerSide/ServerSide')),
//       boxedVariations: lazy(() => import('views/interface/plugins/datatables/BoxedVariations/BoxedVariations')),
//     },
//     lightbox: lazy(() => import('views/interface/plugins/lightbox/Lightbox')),
//     maps: lazy(() => import('views/interface/plugins/maps/Maps')),
//     notification: lazy(() => import('views/interface/plugins/notification/Notification')),
//     players: lazy(() => import('views/interface/plugins/player/Player')),
//     progress: lazy(() => import('views/interface/plugins/progress/Progress')),
//     // scrollbar: lazy(() => import('views/interface/plugins/scrollbar/Scrollbar')),
//     shortcuts: lazy(() => import('views/interface/plugins/shortcut/Shortcut')),
//     sortable: lazy(() => import('views/interface/plugins/sortable/Sortable')),
//   },
//   content: {
//     index: lazy(() => import('views/interface/content/Content')),
//     icons: {
//       index: lazy(() => import('views/interface/content/icons/Icons')),
//       csLineIcons: lazy(() => import('views/interface/content/icons/CsLineIcons')),
//       csInterfaceIcons: lazy(() => import('views/interface/content/icons/CsInterfaceIcons')),
//       bootstrapIcons: lazy(() => import('views/interface/content/icons/BootstrapIcons')),
//     },
//     images: lazy(() => import('views/interface/content/Images')),
//     tables: lazy(() => import('views/interface/content/Tables')),
//     typography: lazy(() => import('views/interface/content/Typography')),
//     menu: {
//       index: lazy(() => import('views/interface/content/menu/Menu')),
//       horizontal:  lazy(() => import('views/interface/content/menu/Horizontal')),
//       vertical: lazy(() => import('views/interface/content/menu/Vertical')),
//       verticalHidden: lazy(() => import('views/interface/content/menu/VerticalHidden')),
//       verticalNoHidden: lazy(() => import('views/interface/content/menu/VerticalNoHidden')),
//       mobileOnly: lazy(() => import('views/interface/content/menu/MobileOnly')),
//       sidebar: lazy(() => import('views/interface/content/menu/Sidebar')),
//     },
//   },
// };
const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/home`,
    },
    {
      path: `${appRoot}/home`,
      component: defaultPages.home,
      
    },
    { path: DEFAULT_PATHS.ABOUTSTEELANDMETALS, exact: true, component: defaultPages.AboutSteelandmetals},
    { path: DEFAULT_PATHS.ADVERTISEWITHUS, exact: true, component: defaultPages.AdvertiseWithUs},
    { path: DEFAULT_PATHS.CAREEROPPORTUNITIES, exact: true, component: defaultPages.CareerOpportunities},
    { path: DEFAULT_PATHS.CONTACTUS, exact: true, component: defaultPages.Contactus},
    { path: DEFAULT_PATHS.CUSTOMERSUPPORT, exact: true, component: defaultPages.CustomerSupport},
    { path: DEFAULT_PATHS.FAQ, exact: true, component: defaultPages.Faq},
    { path: DEFAULT_PATHS.LEGALDISCLAIMER, exact: true, component: defaultPages.LegalDisclaimer},
    { path: DEFAULT_PATHS.MEMBERCONDUCTRULES, exact: true, component: defaultPages.MemberConductRules},
    { path: DEFAULT_PATHS.PRIVACYANDSECURITY, exact: true, component: defaultPages.PrivacyAndSecurity},
    { path: DEFAULT_PATHS.SERVICES, exact: true, component: defaultPages.Services},
    { path: DEFAULT_PATHS.SITEMAP, exact: true, component: defaultPages.Sitemap },
    { path: DEFAULT_PATHS.TROUBLESHOOTING, exact: true, component: defaultPages.Troubleshooting},
    
    { path: DEFAULT_PATHS.LASTAUCTIONPRICESPAGE, exact: true, component: defaultPages.LastAuctionPricesPage },
    { path: DEFAULT_PATHS.MARKETTRENDSPAGE, exact: true, component: defaultPages.MarketTrendsPage },
    { path: DEFAULT_PATHS.MARKETOFFERSPAGE, exact: true, component: defaultPages.MarketOffersPage },
    { path: DEFAULT_PATHS.TESTIMONIALSPAGE, exact: true, component: defaultPages.TestimonialsPage },
    { path: DEFAULT_PATHS.VERIFY_EMAIL, exact: true, component: defaultPages.VerifyEmail },
    { path: DEFAULT_PATHS.UNAUTHORIZED, exact: true, component: defaultPages.Unauthorized },
    { path: DEFAULT_PATHS.INVALID_ACCESS, exact: true, component: defaultPages.InvalidAccess },
    
    
    {
      path: `${appRoot}/icons`,
      component: BootstrapIconsPage,
    },
    
   
    {
      path: `${appRoot}/dashboard`,
      component: dashboard,
      label: 'menu.dashboard',
      icon: 'shop',
      protected: true
    },
    {
      path: `${appRoot}/auction`,
      exact: true,
      redirect: true,
      to: `${appRoot}/auction/live`,
      label: 'menu.schedule',
      icon: 'calendar',
      protected: true,
      roles: [USER_ROLE.ActiveUser],
      subs: [
        { path: '/upcoming-events', label: 'menu.upcoming-event-list', component: auction.upcomingEventList },
        { path: '/live-events', label: 'menu.live', component: auction.eventList },
        { path: '/lot-selection-list', component: auction.lotList },
        { path: '/bid-page', component: auction.bidpage }
        
      ],
    },

    {
      path: `${appRoot}/auction-report`,
      exact: true,
      redirect: true,
      to: `${appRoot}/auction-report/live`,
      label: 'menu.auction',
      icon: 'cart',
      protected: true,
      roles: [USER_ROLE.ActiveUser],
      subs: [
        { path: '/concluded', label: 'menu.conluded', component: reports.concluded },
        { path: '/mycart', label: 'menu.mycart', component: reports.mycart },
      ],
    },
    {
      path: `${appRoot}/ads`,
      exact: true,
      redirect: true,
      to: `${appRoot}/ads/live`,
      label: 'menu.ads',
      icon: 'content',
      protected: true,
      subs: [
        { path: '/my-ads', label: 'menu.myads', component: ad.myAdList },
        { path: '/create-my-ad', component: ad.createMyAd },
        { path: '/update-my-ad', component: ad.updateMyAd },
        { path: '/detail-my-ad/:id', component: ad.detailMyAd },
        { path: '/myad-chatusers-list/:id', component: ad.myAdchatUsers },
        { path: '/chat-list/:id', component: ad.chatAd },
      ],
    },
    {
      path: `${appRoot}/account`,
      exact: true,
      redirect: true,
      to: `${appRoot}/account/edit-profile`,
      label: 'menu.account',
      icon: 'screen',
      protected: true,
      subs: [
        { path: '/edit-profile', label: 'menu.editprofile', component: account.editprofile },
        { path: '/edit-bank-details', label: 'menu.editbankdetails', component: account.editbankdetails },
        { path: '/edit-company-details', label: 'menu.editcompanydetails', component: account.editcompanydetails },

        { path: '/deposit-list', label: 'menu.deposit', component: account.depositList },
        { path: '/membership-list', label: 'menu.membership', component: account.membershipList },
        { path: '/personalize-list', label: 'menu.personalize', component: account.personalizeList },
        { path: '/refund-request', label: 'menu.refund-request', component: account.refundRequest },
        { path: '/refund-request-list', label: 'menu.refund-request-list', component: account.refundRequestList },
        { path: '/upload-documents', label: 'menu.upload-documents', component: account.uploadDocuments },
        { path: '/change-password', label: 'menu.changepassword', component: account.changePassword },
        { path: '/emdblocked-events', label: 'menu.emdblocked-list', component: account.emdBlockedInEvent }

        
      ],
    }
  ],
  sidebarItems: [
  ],
};
export default routesAndMenuItems;
